import * as React from "react"

export function PhoneIcon(props) {
  return (
    <svg
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M492.438 397.75l-2.375-7.156c-5.625-16.719-24.063-34.156-41-38.75l-62.688-17.125c-17-4.625-41.25 1.594-53.688 14.031L310 371.438c-82.453-22.281-147.109-86.938-169.359-169.375l22.688-22.688c12.438-12.438 18.656-36.656 14.031-53.656L160.266 63c-4.625-16.969-22.094-35.406-38.781-40.969l-7.156-2.406c-16.719-5.563-40.563.063-53 12.5L27.391 66.094c-6.063 6.031-9.938 23.281-9.938 23.344C16.266 197.188 58.516 301 134.734 377.219c76.031 76.031 179.453 118.219 286.891 117.313.563 0 18.313-3.813 24.375-9.844l33.938-33.938c12.437-12.437 18.062-36.281 12.5-53z" />
    </svg>
  )
}
