import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout, { PageTitle } from "../components/layout"
import SEO from "../components/seo"
import { Css } from "../components/css"
import { LocationIcon } from "../icons/Location"
import { PhoneIcon } from "../icons/Phone"
import { EmailIcon } from "../icons/Email"
import { Screen } from "../responsive"
import { constants } from "../constants"

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <div css={{ minHeight: "100vh" }}>
        <SEO />
        <PageTitle>Kontaktai</PageTitle>
        <main
          css={{
            display: "grid",
            gridGap: "4rem",
            width: "85vw",
            maxWidth: 1200,
            margin: "0 auto",
            [Screen.XS]: {
              gridTemplateColumns: "1fr 1fr",
            },
          }}
        >
          <section
            css={{ display: "none", [Screen.XS]: { display: "block" } }}
            //   css={{
            //     ...Css.responsiveSection,
            //   }}
          >
            <Img
              style={{
                marginBottom: "3rem",
              }}
              fluid={data.contactImage.childImageSharp.fluid}
              alt={"Laumes - architekturos studija"}
            />
          </section>
          <section>
            <div>
              <a
                target="_blank"
                href="mailto:info@laumes.lt?subject=Uzklausa%20del%20architekturos%20darbu%20(web)"
              >
                <ContactItemWrapper>
                  <EmailIcon height={"1.5rem"} />
                  <span>info@laumes.lt</span>
                </ContactItemWrapper>
              </a>
              <a target="_blank" href="https://goo.gl/maps/zMhoLd81eYvAbM2M8">
                <ContactItemWrapper>
                  <LocationIcon height={"1.5rem"} />
                  <span>Liepu g. 30, Lazdijai</span>
                </ContactItemWrapper>
              </a>
              <a href={`tel:${constants.mainPhone.value}`}>
                <ContactItemWrapper>
                  <PhoneIcon height={"1.5rem"} />
                  <span>{constants.mainPhone.formattedValue}</span>
                </ContactItemWrapper>
              </a>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}

function ContactItemWrapper({ children }) {
  return (
    <div
      css={{
        padding: "1.4rem",
        display: "grid",
        gridAutoFlow: "column",
        gridGap: "2rem",
        gridAutoColumns: "max-content",
        alignItems: "center",
        transition: "all 120ms ease-in",
        ":hover": {
          color: "red",
          fill: "red",
        },
      }}
    >
      {children}
    </div>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    contactImage: file(relativePath: { eq: "contactImage.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
